/* purgecss start ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* purgecss end ignore */

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Recursive", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: var(--color-primary);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  box-shadow: none !important;
  outline: none !important;
}

.monospace {
  --mono: "MONO" 1;
  --casl: "CASL" 0;
  --wght: "wght" 400;
  --slnt: "slnt" 0;
  --CRSV: "CRSV" 0;
  font-variation-settings: var(--mono), var(--casl), var(--wght), var(--slnt),
    var(--CRSV);
}

.line-numbers {
  @apply select-none;
}

.markdown-body div,
.statement div {
  display: unset !important;
  position: relative !important;
  top: unset !important;
  left: unset !important;
  bottom: unset !important;
  right: unset !important;
  box-sizing: unset !important;
  margin: unset !important;
}

.markdown-next-img {
  position: relative !important;
  top: unset !important;
  left: unset !important;
  bottom: unset !important;
  right: unset !important;
  margin: unset !important;
  width: unset !important;
  height: unset !important;
  min-width: unset !important;
  min-height: unset !important;
  max-width: unset !important;
  max-height: unset !important;
}

/* general dropdown */

.filter-select-container {
  @apply rounded-sm;
  @apply text-sm;
  @apply select-none;
}

.filter-select__input {
  color: var(--color-text) !important;
}

.filter-select__control {
  background-color: var(--color-secondary) !important;
  border-radius: 2px !important;
  border-color: transparent !important;
}

.filter-select__placeholder {
  @apply pl-1;
  color: var(--color-text) !important;
}

.filter-select__multi-value {
  background-color: var(--color-text) !important;
}

.filter-select__single-value {
  color: var(--color-text) !important;
}

.filter-select__multi-value__label {
  border-radius: 0px !important;
  color: var(--color-text);
}

.filter-select__multi-value__remove {
  border-radius: 0px !important;
}

.filter-select__indicator-separator {
  visibility: hidden !important;
}

.filter-select__indicator:hover {
  color: var(--color-text) !important;
}

.filter-select__menu {
  @apply cursor-pointer;
  background-color: var(--color-tertiary) !important;
  color: var(--color-text) !important;
}

.filter-select__option--is-focused {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.filter-select__option--is-selected {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.filter-select__option:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.filter-select__multi-value {
  background-color: var(--color-secondary) !important;
}

/* language dropdown */

.language-dropdown .filter-select-container {
  @apply cursor-pointer;
  @apply rounded-sm;
  @apply text-sm;
  @apply select-none;
}

.language-dropdown .filter-select__input {
  color: var(--color-themeForeground) !important;
}

.language-dropdown .filter-select__control {
  @apply cursor-pointer;
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(50%, 50%, 50%, 40%) !important;
  transition: 0.2s;
}

.language-dropdown .filter-select__control:hover {
  background-color: rgba(50%, 50%, 50%, 20%) !important;
  transition: 0.2s;
}

.language-dropdown .filter-select__indicator-separator {
  visibility: hidden !important;
}

.language-dropdown .filter-select__menu {
  @apply rounded-sm;
  background-color: var(--color-themeBackground) !important;
  color: var(--color-themeForeground) !important;
  border: 1px solid rgba(50%, 50%, 50%, 20%) !important;
}

.language-dropdown .filter-select__option {
  color: var(--color-themeForeground) !important;
  @apply cursor-pointer;
}

.language-dropdown .filter-select__option--is-focused {
  background-color: rgba(0, 0, 0, 0) !important;
  transition: 0.2s;
}

.language-dropdown .filter-select__option--is-selected {
  background-color: rgba(50%, 50%, 50%, 10%) !important;
  transition: 0.2s;
}

.language-dropdown .filter-select__option:hover {
  background-color: rgba(50%, 50%, 50%, 20%) !important;
  transition: 0.2s;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  -webkit-border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: var(--color-primary);
}

::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204, 0.33);
  border-radius: 2.5px;
  -webkit-border-radius: 2.5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(204, 204, 204, 0.33);
}

.graphviz {
  background-color: transparent;
  fill: transparent;
}

.graphviz .graph polygon {
  fill: transparent;
}

.graphviz .graph .node * {
  stroke: white;
}

.graphviz .graph .node text {
  stroke: transparent;
}

.graphviz .graph .edge * {
  stroke: white;
}

.graphviz .graph .edge polygon {
  fill: white;
}

.graphviz .graph text {
  fill: white;
}
